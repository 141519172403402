import React, { useState, useEffect } from 'react';
import { ReactSession }  from 'react-client-session';

const NavBar = () => {
    const userName = ReactSession.get("userName");
    
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []); 
  
    const formattedDateTime = currentDateTime.toLocaleString();

    const sideMenu = () => {
      let body = document.body;
      const className = "g-sidenav-pinned";
      body.classList.toggle(className);
  }

  return (
   
      
    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl " id="navbarBlur" data-scroll="false">
      <div class="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <h6 class="font-weight-bolder text-white mb-0">{userName} </h6>
        </nav>
        <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          <div class="ms-md-auto pe-md-3 d-flex align-items-center">
          
          </div>
          <ul class="navbar-nav  justify-content-end">
            <li class="nav-item dropdown px-3 d-flex align-items-center">
              <p class="text-sm my-4 text-white font-weight-bold px-2">{formattedDateTime}</p>
            </li>
            <li class="nav-item d-flex align-items-center">
              <a href="logout" class="nav-link text-white font-weight-bold px-0">
             
                <span class="d-sm-inline d-none">Logout</span>
              </a>
            </li>
            <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a   href="javascript:;" class="nav-link text-white p-2" id="iconNavbarSidenav1" onClick={() => sideMenu()}>
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line bg-white"></i>
                  <i class="sidenav-toggler-line bg-white"></i>
                  <i class="sidenav-toggler-line bg-white"></i>
                </div>
              </a>
            </li>
           
            
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;