
import React from 'react';
import logo from '../assets/img/logos/logo_007.png';

const SideBar = () => {
  return (
  
  
    <aside class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 " id="sidenav-main">
    <div class="sidenav-header">
      <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
      <a class="navbar-brand m-0" href="/dashboard">
        <img src={logo} class="navbar-brand-img h-100" alt="main_logo" />
        <span class="ms-1 font-weight-bold">Resto Control</span>
      </a>
    </div>
    <hr class="horizontal dark mt-0" />
    <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" href="/dashboard">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-desktop text-primary text-bold opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Dashboard</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/product">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-list-alt text-warning text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Product</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/category">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-list-alt text-warning text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Category</span>
          </a>
        </li>
       
        <li class="nav-item mt-3">
          <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Orders</h6>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/order">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-list-alt text-warning text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Take Order</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link " href="/dinein">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-cutlery text-warning text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Restaurant Orders</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link " href="/whatsapp">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-whatsapp text-warning text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">WhatsApp Orders</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link " href="/website">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="ni ni-single-copy-04 text-warning text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Website Orders</span>
          </a>
        </li>
        
		 
		<li class="nav-item mt-3">
          <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">REstaurant Manage</h6>
        </li>
        
        <li class="nav-item">
          <a class="nav-link " href="/manage">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-sliders text-primary text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Table Manage</span>
          </a>
        </li>
      {/* <li class="nav-item">
          <a class="nav-link " href="/dashboard">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-calculator text-primary text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Reports</span>
          </a>
  </li> */}
		 <li class="nav-item">
          <a class="nav-link " href="/contacts">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-address-book text-primary text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Contacts</span>
          </a>
        </li>
		<li class="nav-item mt-3">
          <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Employees Manage</h6>
        </li>
        <li class="nav-item">
          <a class="nav-link " href="/employee">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-address-card text-secondary text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Employees</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link " href="/salary">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-money text-secondary text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Salary</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link " href="/logout">
            <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
              <i class="fa fa-power-off text-danger text-sm opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Logout</span>
          </a>
        </li>
      </ul>
    </div>
    
  </aside>
  
        
  );
};

export default SideBar;


