import React, { useEffect, useState } from 'react';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';
import { Paginator } from 'primereact/paginator';
ReactSession.setStoreType("localStorage");

 function Product() {
  const [product, setproduct]=useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(product.data);
  const [first, setFirst] = useState(0); 
  const [rows, setRows] = useState(10); 

  const fetchData = async () => {
    try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/editproduct/');
      console.log(response.data);
        if(response.data.status === "success"){
            setproduct(response.data);
        }else{
          console.error(response.data);
        }
       
       //navigate(`/product`);
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
    }
};
  useEffect(()=>{
fetchData();
}, []);


const handleSearchInputChange = (event) => {
	  
	
  const query = event.target.value;
  setSearchQuery(query);

  // Filter datas.data based on search query
  const filtered = product.data.filter(product =>
    product.product.toLowerCase().includes(query.toLowerCase()) ||
    product.maincat.toLowerCase().includes(query.toLowerCase()) ||
    product.cat.toLowerCase().includes(query.toLowerCase())
  );
  setFilteredData(filtered);


};




const handleChange = async(productId, visibility) => {
  let visible = null;
  if(visibility === '1'){
   visible = '0';
  }else{
   visible = '1';
  }
  console.log(visible, productId);
 const response = await axios.put('https://projects.sninfoserv.com/fbh-api/editproduct/', { action:'visible', id: productId, value:visible });
 if(response.data.status === 'success'){
   fetchData();
}
};


const handleChangeDelete = async(productId, deletees) => {
  let deletes = null;
  if(deletees === '1'){
    deletes = '0';
  }else{
    deletes = '1';
  }
  console.log(deletes, productId);
 const response = await axios.put('https://projects.sninfoserv.com/fbh-api/editproduct/', { action:'delete', id: productId, value:deletes });
 if(response.data.status === 'success'){
   fetchData();
}
};

 const onPageChange = (event) => {
      setFirst(event.first);
      setRows(10); // Update rows to display only one row per page
    };


  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <SideBar />

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Products
                <a href="\add_product" class="btn btn-info btn-sm ms-auto" style={{float:'right',}} > <i class="fa fa-plus"></i>Add Product</a>
              </h6>
                   </div>

                   <div className="col-lg-3 px-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by product name or category or Main category..."
             value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
            <div class="card-body px-0 pt-0 pb-2">

          

              <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                 
                    <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Product</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Product Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Price</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder  opacity-7">Main Category</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Stock</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Action</th>
                     
                    </tr>
                  
                  </thead>
                  <tbody>
                  {searchQuery && filteredData.length > 0 ? (
            <>  {filteredData.map(product => ( 
              <tr >
              <td>
              {product.image ? (
                  <img src={`https://projects.sninfoserv.com/fbh-api/product_data/${product.image}`} alt="Avatar" className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle" width="50%" />
                ) : (
                  <img  src={'https://projects.sninfoserv.com/fbh-api/no_image.webp'}  alt="No Image" className="rounded-circle" />
                )}
              </td>
               <td>
                <span class="text-xs font-weight-bold">{product.cat}</span>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{product.price}</span>
              </td>
              <td>
                <span class="text-xs font-weight-bold">{product.maincat}</span>
              </td>
             
             


              <td>
                <span class="text-xs font-weight-bold">{product.stock > 0 ? (<>{product.stock}</>):(<>0</>)}</span>
              </td>
              <td>
              <a  class="btn btn-info btn-sm ms-auto" onClick={() => handleChange(product.p_id, product.visibility)} style={{float:'left',}} > <i class={ product.visibility === '1' ?"fa fa-eye-slash" :"fa fa-eye"}></i></a>
               <a href={`edit_product?id=${product.p_id}`} class="btn btn-primary btn-sm ms-auto" style={{float:'left',}}><i class="fa fa-edit"></i></a>
              <a  class="btn btn-warning btn-sm ms-auto" onClick={() => handleChangeDelete(product.p_id, product.delete)} style={{float:'left',}} > <i class="fa fa-trash"></i></a>
             
              </td>
             
              
            </tr>
            ))}
            </>
            
            ) : searchQuery && filteredData.length === 0 ? (
              <tr >
                           <td>No products found </td>
                         </tr>
           ) : (<>
                  {product?.data?.slice().reverse().slice(first, first + rows).map(product => (
                    <tr >
                      <td>
                      {product.image ? (
                          <img src={`https://projects.sninfoserv.com/fbh-api/product_data/${product.image}`} alt="Avatar" className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle" width="50%" />
                        ) : (
                          <img  src={'https://projects.sninfoserv.com/fbh-api/no_image.webp'}  alt="No Image" className="rounded-circle" />
                        )}
                      </td>
                       <td>
                         <span class="text-xs font-weight-bold">{product.cat}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{product.price}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{product.maincat}</span>
                      </td>
                     
                   


                      <td>
                        <span class="text-xs font-weight-bold">{product.stock > 0 ? (<>{product.stock}</>):(<>0</>)}</span>
                      </td>
                      <td>
                      <a  class="btn btn-info btn-sm ms-auto" onClick={() => handleChange(product.p_id, product.visibility)} style={{float:'left',}} > <i class={ product.visibility === '1' ?"fa fa-eye-slash" :"fa fa-eye"}></i></a>
                       <a href={`edit_product?id=${product.p_id}`} class="btn btn-primary btn-sm ms-auto" style={{float:'left',}}><i class="fa fa-edit"></i></a>
                      <a  class="btn btn-warning btn-sm ms-auto" onClick={() => handleChangeDelete(product.p_id, product.delete)} style={{float:'left',}} > <i class="fa fa-trash"></i></a>
                     
                      </td>
                     
                      
                    </tr>
                   ))}
                  </>)}
                  </tbody>
                </table>
                <Paginator className="custom-paginator" first={first} rows={rows} totalRecords={product.data ? product.data.length : 0}  onPageChange={onPageChange} />
             

              </div>
            </div>
          </div>
        </div>
      </div>
   
      <Footer />
    </div>
  </main>

  </div>
  )
}
export default Product;
