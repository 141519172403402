import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';

function OrderDetailsPopup({ order }) {
  // Render the popup content here based on the order details
  return (
    <div className="col-lg-12 col-md-12 col-12 mb-4" style={{ float:'left',top:'20vh'}}>
    <div className="card">
    <div class="card-header pb-0">
              <h6>New Order</h6>
            </div>
      <div className="card-body">
        <div className="card-title d-flex align-items-start justify-content-between">
          <h6 className="mb-0 text-sm">{order.order_customer} - {order.order_contact}</h6>
          
          <a href={`orderdetails?id=${order.order_id}`}><button className="btn btn-success btn-sm ms-auto">Order Details</button></a>
        </div>
        <span className="fw-semibold d-block mb-1">{order.order_status}</span>
        <h3 className="card-title mb-2">₹ {order.order_amount}</h3>
        <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i>{order.order_time}</small>
      </div>
    </div>
  </div>
  );
}

function App() {
  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  const orderPath = '/order';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    if (userType == 2 ) {
      navigate(orderPath);
    }
  }, [userId, navigate]);

  const [whatsappData, setWhatsappData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/whatsapp/orders/');
        setWhatsappData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="g-sidenav-show bg-gray-100">
      <div className="min-height-300 bg-primary position-absolute w-100"></div>
      { userType === '1' ? (
        <SideBar />
      ) : (
        <EsideBar />
      )}
      <main className="main-content position-relative border-radius-lg">
        <NavBar />
        <div className="container-fluid py-4">
          
        <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">All Orders</p>
                    {whatsappData && whatsappData.data && whatsappData.data.whatsapp.length > 0 ? (
                        <h5 class="font-weight-bolder"> {whatsappData.data.whatsapp[0].whatsapp_orders}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                   
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                    <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Delivered</p>
                    {whatsappData && whatsappData.data && whatsappData.data.whatsapp.length > 0 ? (
                        <h5 class="font-weight-bolder"> {whatsappData.data.whatsapp[0].whatsapp_delivered}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                    <i class="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Cod Orders</p>
                    {whatsappData && whatsappData.data && whatsappData.data.whatsapp.length > 0 ? (
                        <h5 class="font-weight-bolder"> {whatsappData.data.whatsapp[0].whatsapp_cod}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                    <i class="ni ni-paper-diploma text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Online Payments</p>
                    {whatsappData && whatsappData.data && whatsappData.data.whatsapp.length > 0 ? (
                        <h5 class="font-weight-bolder"> {whatsappData.data.whatsapp[0].whatsapp_online}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                    <i class="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

          <div className="row mt-5">
            
            {whatsappData && whatsappData.data && whatsappData.data.orders && whatsappData.data.orders.map(whatsapp => (
              <>
              {whatsapp.order_view === 'no' && (
                <OrderDetailsPopup order={whatsapp} />
              )}
              
              <div className="col-lg-4 col-md-12 col-12 mb-4" key={whatsapp.order_id}>
                <div className="card">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-start justify-content-between">
                      <h6 className="mb-0 text-sm">{whatsapp.order_customer}</h6>
                      
                      <a href={`orderdetails?id=${whatsapp.order_id}`}><button className="btn btn-success btn-sm ms-auto">Order Details</button></a>
                    </div>
                    <span className="fw-semibold d-block mb-1">{whatsapp.order_status}</span>
                    <h3 className="card-title mb-2">₹ {whatsapp.order_amount}</h3>
                    <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i>{whatsapp.order_time}</small>
                  </div>
                </div>
              </div>
              </>
            ))}
          </div>
          {/* End of content */}
        </div>
        <Footer />
      </main>
    </div>
  );
}

export default App;
