import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import SideBar from './sidebar';
import NavBar from './navbar';
import Footer from './footer';

 function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  const orderPath = '/order';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    if (userType == 2 ) {
      navigate(orderPath);
    }
  }, [userId, navigate]);

  const [employees, setData] = useState(true);

 
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/employees/');
        setData(response.data);
        console.log(response.data);
      } catch (error) {
      } 
    };

  useEffect(() => {
    fetchData();
  }, []);

  const [AddData, setAddData] = useState(true);
  const  removeEmployee = async (employee) => {
    try {
      const response =  await axios.post('https://projects.sninfoserv.com/fbh-api/employees/delete/', { employeeId: employee });
      setAddData(response.data);
      fetchData();
    } catch (error) {
      console.error('Error posting hut_id:', error);
      
    }
  };

  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <SideBar />

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Employees
              <a class="btn btn-warning btn-sm ms-auto" href="/add-employee" style={{float:'right',}} > <i class="fa fa-plus"></i></a>
              </h6>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                 
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Position</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder  opacity-7">Email</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Phone</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">State</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Country</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">ACTION</th>
                      <th></th>
                    </tr>
                  
                  </thead>
                  <tbody>
                  {employees && employees.data && employees.data.map(employee => (
                    <tr>
					  <td>
                        <div class="d-flex px-2">
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">{employee.employee_name}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex px-2">
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">{employee.employee_position}</h6>
                          </div>
                        </div>
                      </td>
                       <td>
                        <div class="d-flex px-2">
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">{employee.employee_email}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex px-2">
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">{employee.employee_phone}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{employee.employee_city}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{employee.employee_country}</span>
                      </td>
                      <td class="align-middle text-center">
                          <a href={`edit-profile?id=${employee.employee_id}`} class="mx-2">
                          <button class="btn btn-success btn-sm ms-auto">
                               Edit
                            </button>
                          </a>
                          <a href="javascript:;">
                            <button class="btn btn-danger btn-sm ms-auto" onClick={() => removeEmployee(employee.employee_id)}>
                               <i class="far fa-trash-alt"></i>
                            </button>
                          </a>
                      </td>
                      <td class="align-middle text-center">
                     
                   
                      </td>
                    </tr>
                    ))}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
   
      <Footer />
    </div>
  </main>

  </div>
  )
  
}
export default App;