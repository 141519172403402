import React, { useEffect, useState } from 'react';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';
ReactSession.setStoreType("localStorage");

function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const userName = ReactSession.get("userName");
  const [product, setproduct]=useState([]);
  const [stocks, setstocks]=useState('');
  const [qty, setQty] = useState('0');
  const navigate = useNavigate();
  const indexPath = '/';
  console.log("stock",stocks);
  console.log("qty",qty);
 

  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
  }, [userId, navigate]);

  const [datas, setData] = useState(true);



  const fetchData = async () => {
    try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/editproduct/');
      console.log(response.data);
        if(response.data.status === "success"){
            setproduct(response.data);
        }else{
          console.error(response.data);
        }
       
       //navigate(`/product`);
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
    }
};
  useEffect(()=>{
fetchData();
}, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/products/');
        setData(response.data);
       // console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);

  const [hutData, setHutData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/hut/');
        setHutData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);

  const [rfTableData, setrfTableData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/roof_top/');
        setrfTableData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);

  const [outerData, setouterData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/outer/');
        setouterData(response.data);
       // console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);

  const [acHallData, setacHallData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/dine/ac_hall/');
        setacHallData(response.data);
       // console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);

 
  
  const [formError, setError]=useState({});
  const [orders, setOrders] = useState([]);
  const [section, setHut] = useState('');
  const [item, setItem] = useState('');
  const [price, setPrice] = useState('');
  
  const [area, setArea] = useState('TakeAway');
  const [user, setUser] = useState(userId);
  const [nextId, setNextId] = useState(1);
  const [amount, setAmount] = useState(0);
  const [cName, setcName] = useState('');
  const [cPhone, setcPhone] = useState('');
  const [cAddress, setcAddress] = useState('');




 
  
  const handleLinkClick = (newValue) => {
    setArea(newValue);
  };
  
  const itemChange = (e) => {
    setItem(e.target.value);
    checkProduct(e.target.value);
  };
  
  const checkProduct = (Currentitem) => {
    console.log("Current item:", Currentitem); // Log the current item
    console.log("Product data:", product?.data); // Log product data
  
    const foundProducts = product?.data?.filter(prod => prod.cat === Currentitem);
    console.log("Found products:", foundProducts); // Log found products
  
    if (foundProducts && foundProducts.length > 0) {
      foundProducts.forEach(foundProduct => {
        if (parseInt(foundProduct.stock) > 0) {
          alert(`${foundProduct.cat} ${foundProduct.stock} is in stock.`);
        } else {
          alert(`${foundProduct.cat} is out of stock.`);
        }
      });
    } else {
    //  alert("Product not found."); // Uncomment this to alert if no products found
    }
  };
  
  
  const checkProductqty = (Currentitem, qtys) => {
    console.log("Current item:", Currentitem); // Log the current item
    console.log("Product data:", product?.data); // Log product data
    
    // Find the product by category (Currentitem)
    const foundProducts = product?.data?.filter(prod => prod.cat === Currentitem);
    console.log("Found products:", foundProducts); // Log found products
    
    // If product is found
    if (foundProducts && foundProducts.length > 0) {
      const productStock = foundProducts[0]?.stock;
      setstocks(productStock);
     // console.log("Product stock:", productStock); 
     // console.log("Requested qty:", qtys); 
      
      if (Number(qtys) <= Number(productStock)) {
        // If stock is sufficient, proceed to add to cart
        alert(`${foundProducts[0]?.cat} - ${productStock} items are available in stock.`);
  
       
        // Reduce stock by the ordered quantity
      } else {
        // If stock is insufficient, show an alert
        alert(`${foundProducts[0]?.cat} - Only ${productStock} items are available in stock. Please reduce the quantity. ${qtys}`);
      }
    } else {
      // If no products were found for the given category
      alert("Product not found.");
    }
  };
  
  
  
  
  


  
  

  const qtyChange = (e) => {
    setQty(e.target.value);
      fetchPrice();
    checkProductqty(item, e.target.value);
  };
  
  
  const fetchPrice = () => {
    const productId = product?.data.find(items => items.cat === item);
    console.log("productId",productId);
    if (productId) {
      setPrice(productId?.price);
    } else {
      setPrice(null);
      console.log('Open Product');
    }
    setAmount(amount + (price * qty));
  };
  
  const handleSubmit = (event) => {
    alert(qty +' - '+stocks);
    if (Number(qty) > Number(stocks) ) {
      alert("Not enough stock available for the requested quantity.");
      // Prevent the default form submission behavior
      event.preventDefault();
    } else {
      event.preventDefault();
  
      // Create the new order object with the current state values
      const newOrder = {
        id: nextId,
        user: userId,
        area: area,
        section: section,
        item: item,
        price: price,
        qty: qty,
        cName: cName,
        cPhone: cPhone,
        cAddress: cAddress,
        amount: amount
      };
  
      // Update the orders array with the new order
      setOrders([...orders, newOrder]);
  
      // Increment the nextId for the next order
      setNextId(nextId + 1);
  
      // Reset values after order submission
      setHut(section);
      setItem('');
      setPrice('');
      setQty('');
      setArea(area); // This seems redundant, but keeping it for clarity
      setUser(user); // This is also redundant if already set elsewhere
      setAmount(amount + (qty * price)); // Update the total amount after the order is added
    }
  };
  


  
 


  const handlestock = async () => {

    try {
      const response = await axios.post('https://projects.sninfoserv.com/fbh-api/order/', orders);
      console.log(orders);
      if(response.data.status === 'success' ){
        //console.log(response.data);
        handlePrint(generateTableContent());
        handleDeleteAll();
          stock();
     
      }else{
        console.log(response.data.error.message);
      }
      setError(validate(response));
      } catch (error) {
        console.error('Login failed!', error);
      }
  };


  const stock = async () => {
    try {
      

        const mappedDetails = orders.map((order) => {
            return {
                itemName: order.item, // Adjust this based on your actual data structure
                itemQuantity: order.qty // Adjust this based on your actual data structure
            };
        });

        console.log(mappedDetails);

        const response = await axios.post('https://projects.sninfoserv.com/fbh-api/stock/', {
            mappedDetails
        });
       
       console.log(response.data);
    } catch (error) {
        console.error('Error:', error);
        // Handle error if needed
    }
};



  const handleOrder = async () => {

    try {
      const response = await axios.post('https://projects.sninfoserv.com/fbh-api/order/', orders);
      console.log(orders);
      if(response.data.status === 'success' ){
        //console.log(response.data);
       
        handlePrint(generateTableContent());
        handleDeleteAll();
      }else{
        console.log(response.data.error.message);
      }
      setError(validate(response));
      } catch (error) {
        console.error('Login failed!', error);
      }
  };

  const validate=(response)=>{
    const errors={};
      if(response.data.status === 'success'){
        errors.name = response.data.status;
        return errors;
      }else{
        errors.name = response.data.error.message;
        return errors;
      }
      
  }

  useEffect(()=>{
    console.log(formError);
    if(Object.keys(formError).length ===0 & handleOrder){
      
    }
  });
 
  const handleDelete = (id) => {
    const updatedOrders = orders.filter((order) => order.id !== id);
    setOrders(updatedOrders);
  };
  
  const handleDeleteAll = () => {
    setOrders([]);
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []); 
  
    const formattedDateTime = currentDateTime.toLocaleString();
  

  const handlePrint = (content) => {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('style', 'display: none;');
    document.body.appendChild(iframe);
    const iframeDoc = iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(content);
    iframeDoc.close();
    iframe.contentWindow.print();
  };

  const generateTableContent = () => {
    return `
      <html>
      <head>
        <title>Order Details</title>
        <style>
         
          body {
            margin: 0px; 
          }
          .header {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 5px; 
          }
          .address {
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 0px; 
          }
          .content {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .left {
            text-align:left;
          }
          .right{
            text-align:right;
          }
        </style>
      </head>
      <body>
        <div class="header">KOT-${userName}</div>
        <div class="address">${formattedDateTime}</div><br>
          <table align="center">
              ${orders.map(
                (order) => `
                <tr align="center">
                  <td class="content left" width="85%">${order.item}</td>
                  <td class="content right">${order.qty}</td>
                </tr>`
              )}
          </table><br>
          <div class="address">${area + ', ' + section}</div>
          
      </body>
      </html>
    `;
  };
  

  const generateShortcuts = (productName) => {
    const words = productName;
    let shortcuts = "";
   
    return shortcuts.toUpperCase();
  };

  return (
    
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
    <div class="card shadow-lg mx-2 mt-5 card-profile-bottom">
      <div class="card-body p-2">
        <div class="row gx-4">
          <div class="col-12">
               <div class="nav-wrapper position-relative end-0">
              <ul class="nav nav-pills nav-fill p-1 " role="tablist">
			  
                <li class="nav-item p-2">
                  <a class="nav-link mb-0 px- py-2 active  d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('TakeAway')} data-bs-toggle="tab" data-bs-target="#takeAway" role="tab" aria-selected="true" rel="noreferrer">
                    <i class="ni ni-basket"></i>
                    <span class="ms-2">Take Away</span>
                  </a>
                </li>
                <li class="nav-item p-2">
                  <a class="nav-link mb-0 px- py-2 d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('Delivery')} data-bs-toggle="tab" data-bs-target="#delivery" role="tab" aria-selected="true" rel="noreferrer">
                    <i class="ni ni-delivery-fast"></i>
                    <span class="ms-2">Delivery</span>
                  </a>
                </li>
                <li class="nav-item p-2">
                  <a class="nav-link mb-0 px- py-2 d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('Hut')} data-bs-toggle="tab" data-bs-target="#Hut" role="tab" aria-selected="true" rel="noreferrer">
                    <i class="ni ni-shop"></i>
                    <span class="ms-2">HUT</span>
                  </a>
                </li>
                <li class="nav-item p-2">
                  <a class="nav-link mb-0 px-0 py-2 d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('AC_Hall')} data-bs-toggle="tab" data-bs-target="#ACHALL" role="tab" aria-selected="false" rel="noreferrer">
                    <i class="ni ni-tie-bow"></i>
                    <span class="ms-2">AC HALL</span>
                  </a>
                </li>
                <li class="nav-item p-2">
                  <a class="nav-link mb-0 px-0 py-2 d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('Outer_Table')} data-bs-toggle="tab" data-bs-target="#outertable" role="tab" aria-selected="false" rel="noreferrer">
                    <i class="ni ni-image"></i>
                    <span class="ms-2">OUTER TABLES</span>
                  </a>
                </li>
				        <li class="nav-item p-2">
                  <a class="nav-link mb-0 px-0 py-2 d-flex align-items-center justify-content-center " href="javascript;" onClick={() => handleLinkClick('Roof_Top')} data-bs-toggle="tab" data-bs-target="#rooftop" role="tab" aria-selected="false" rel="noreferrer">
                    <i class="ni ni-box-2"></i>
                    <span class="ms-2">ROOF TOP TABLES</span>
                  </a>
                </li>
              </ul>
			  <div class="tab-content">
					
					 
        <div class="tab-pane fade show active" id="takeAway" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
             <form onSubmit={handleSubmit}>
              <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Customer Name</label>
                    <input type="text"  class="form-control"  value={cName}  placeholder="Enter Customer Name..." onChange={(e) => setcName(e.target.value)} />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Customer Phone Number</label>
                    <input type="number"  class="form-control" placeholder="Enter Customer Phone..." value={cPhone} onChange={(e) => setcPhone(e.target.value)} />
                  </div>
                </div>
                <table class="col-md-12 mx-4 text-center text-sm" style={{background:"#F9F9F9"}}>
                 {orders.map((order) => (
                   <tr>
                        <td width="70%">{order.item}</td>
                        <td width="8%">{order.qty} </td>   
                        <td width="12%">{order.qty * order.price} </td>  
                        <td width="10%">
                        <button class="btn btn-danger btn-m ms-0 my-2 me-2"  onClick={() => handleDelete(order.id)}><i class="ni ni-basket"></i></button>
                        </td>
                   </tr>
                  ))}
                </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                        <option key={product.product_id} value={product.cat}>
                              {generateShortcuts(product.product_cat)} - {product.cat}
                        </option>
                    ))}
                    </datalist>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />      
                  </div>
                </div>
               
    <div className="col-md-2">
      <div className="form-group">
        <label htmlFor="example-text-input" className="form-control-label">Price</label>
        <input
          type="number"
          className="form-control"
          name="price"
          placeholder="Enter Price..."
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
      </div>
    </div>
    <div className="col-md-2 my-4 py-2">
      <button className="btn btn-primary btn-sm ms-auto">ADD</button>
    </div>
 

              </div>
              </form>
              <div class="col-md-3">
                   <button class="btn btn-success btn-m " onClick={() => handlestock()}>Confirm Order</button>
              </div>
             
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
              

                
            </div>
					   </div>

             <div class="tab-pane fade" id="delivery" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
              <form onSubmit={handleSubmit}>
              <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Customer Name</label>
                    <input type="text"  class="form-control"  value={cName}  placeholder="Enter Customer Name..." onChange={(e) => setcName(e.target.value)} />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Customer Phone Number</label>
                    <input type="number"  class="form-control" placeholder="Enter Customer Phone..." value={cPhone} onChange={(e) => setcPhone(e.target.value)} />
                  </div>
                </div>
                  <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Address</label>
                   <input  class="form-control"  placeholder="Enter Customer Address..." value={cAddress} onChange={(e) => setcAddress(e.target.value)} />
                  </div>
                </div>
                <table class="col-md-12 mx-4 text-center text-sm" style={{background:"#F9F9F9"}}>
                 {orders.map((order, index) => (
                   <tr key={index} >
                        <td width="70%">{order.item}</td>
                        <td width="8%">{order.qty} </td>   
                        <td width="12%">{order.qty * order.price} </td>  
                        <td width="10%">
                        <button class="btn btn-danger btn-m ms-0 my-2 me-2"  onClick={() => handleDelete(order.id)}><i class="ni ni-basket"></i></button>
                        </td>
                   </tr>
                  ))}
                </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                        <option key={product.product_id} value={product.cat}>
                              {generateShortcuts(product.product_cat)} - {product.cat}
                        </option>
                    ))}
                    </datalist>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />      
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Price</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Price..." value={price} onChange={(e) => setPrice(e.target.value)} required />       
                  </div>
                </div>
                <div class="col-md-2 my-4 py-2">
                   <button class="btn btn-primary btn-sm ms-auto">ADD</button>
                </div>
              </div>
              </form>
              <div class="col-md-3">
                   <button class="btn btn-success btn-m ms-auto" onClick={() => handlestock()}>Confirm Order</button>
              </div>
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
                
            </div>
					   </div>


           <div class="tab-pane fade" id="Hut" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
              <form onSubmit={handleSubmit} >
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">SELECT TABLE</label>
                    <select class="form-control" name="section" value={section} onChange={(e) => setHut(e.target.value)} required >
                      <option value="" disabled>Select a Hut</option>
                      {hutData && hutData.data && hutData.data
                        .filter(hut => hut.hut_status == 'yes')
                        .map(hut => (
                        <option key={hut.hut_id}>{hut.hut_name}</option>
                      ))}
						         </select>
                  </div>
                </div>

                <table class="col-md-12 mx-0 text-center text-sm" style={{background:"#f9f9f9",border:"1px solid #23fe45"}}> 
                 {orders.map((order, index) => (
                  
                   <tr key={index} >

                        <td width="70%">{order.item}</td>
                        <td width="8%">{order.qty} </td>   
                        <td width="12%">{order.qty * order.price} </td>  
                        <td width="10%">
                        <button class="btn btn-danger btn-m ms-0 my-2 me-2"  onClick={() => handleDelete(order.id)}><i class="ni ni-basket"></i></button>
                        </td>
                   </tr>
                  ))}
                </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                        <option key={product.product_id} value={product.cat}>
                              {generateShortcuts(product.product_cat)} - {product.cat}
                        </option>
                    ))}
                    </datalist>           
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />      
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Price</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Price..." value={price} onChange={(e) => setPrice(e.target.value)} required />       
                  </div>
                </div>
                <div class="col-md-2 my-4 py-2">
                   <button class="btn btn-primary btn-sm ms-auto">ADD</button>
                </div>
                
              </div>
              </form>
              <div class="col-md-3">
                   <button class="btn btn-success btn-m ms-auto" onClick={() => handleOrder()}>Confirm Order</button>
              </div>
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
                
            </div>
					   </div>
					   
					   
					 <div class="tab-pane fade" id="ACHALL" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
              <form onSubmit={handleSubmit}>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">SELECT TABLE</label>
                    <select class="form-control" name="section" value={section} onChange={(e) => setHut(e.target.value)} required >
						      <option value="" disabled>Select a Table</option>
                  {acHallData && acHallData.data && acHallData.data
                      .filter(acHall => acHall.table_status == 'yes')
                      .map(acHall=> (
                        <option key={acHall.table_id}>{acHall.table_name}</option>
                    ))}
					        </select>
                  </div>
                </div>

                <table class="col-md-12 mx-0 text-center text-sm" style={{background:"#f9f9f9",border:"1px solid #23fe45"}}> 
                 {orders.map((order, index) => (
                  
                   <tr key={index} >

                        <td width="70%">{order.item}</td>
                        <td width="8%">{order.qty} </td>   
                        <td width="12%">{order.qty * order.price} </td>  
                        <td width="10%">
                        <button class="btn btn-danger btn-m ms-0 my-2 me-2"  onClick={() => handleDelete(order.id)}><i class="ni ni-basket"></i></button>
                        </td>
                   </tr>
                  ))}
                </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                             <option key={product.product_id} value={product.cat}>
                                {generateShortcuts(product.product_cat)} - {product.cat}
                             </option>
                    ))}
                    </datalist>           
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />
                                
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Price</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Price..." value={price} onChange={(e) => setPrice(e.target.value)} required />
                                
                  </div>
                </div>
                <div class="col-md-2 my-4 py-2">
                   <button class="btn btn-primary btn-sm ms-auto">ADD</button>
                </div>

              </div>
              </form>
              <div class="col-md-3">
                   <button class="btn btn-success btn-m ms-auto" onClick={() => handleOrder()}>Confirm Order</button>
              </div>
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
            </div>
					   </div>  
					   
					   
					   
					   
					   <div class="tab-pane fade" id="outertable" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
              <form onSubmit={handleSubmit} >
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">SELECT TABLE</label>
                    <select class="form-control" name="section" value={section} onChange={(e) => setHut(e.target.value)} required >
						      <option value="" disalbed>Select a Table</option>
						      
                  {outerData && outerData.data && outerData.data
                      .filter(outer => outer.table_status == 'yes')
                      .map(outer => (
                             <option key={outer.table_id}>{outer.table_name}</option>
                    ))}

						  </select>
                  </div>
                </div>

                <table class="col-md-12 mx-0 text-center text-sm" style={{background:"#f9f9f9",border:"1px solid #23fe45"}}> 
                 {orders.map((order, index) => (
                  
                   <tr key={index} >

                        <td width="70%">{order.item}</td>
                        <td width="8%">{order.qty} </td>   
                        <td width="12%">{order.qty * order.price} </td>  
                        <td width="10%">
                        <button class="btn btn-danger btn-m ms-0 my-2 me-2"  onClick={() => handleDelete(order.id)}><i class="ni ni-basket"></i></button>
                        </td>
                   </tr>
                  ))}
                </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                            <option key={product.p_id} value={product.cat}>
                                {generateShortcuts(product.cat)} -{product.cat}
                            </option>
                    ))}
                    </datalist>           
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />
                                
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Price</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Price..." value={price} onChange={(e) => setPrice(e.target.value)} required />
                                
                  </div>
                </div>
                <div class="col-md-2 my-4 py-2">
                   <button class="btn btn-primary btn-sm ms-auto">ADD</button>
                </div>

              </div>
              </form>

              <div class="col-md-3">
                   <button class="btn btn-success btn-m ms-auto" onClick={() => handleOrder()}>Confirm Order</button>
              </div>
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
            </div>
					   </div>  
					   
					   
					   
					   
					   <div class="tab-pane fade" id="rooftop" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add ITEMS</p>
              <form onSubmit={handleSubmit} >
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">SELECT TABLE</label>
                    <select class="form-control" name="section" value={section} onChange={(e) => setHut(e.target.value)} required >
						      <option value="" disabled>Select a Table</option>
						      {rfTableData && rfTableData.data && rfTableData.data
                    .filter(rfTable => rfTable.table_status == 'yes')
                    .map(rfTable => (
                             <option key={rfTable.table_id}>{rfTable.table_name}</option>
                    ))}
					</select>
                  </div>
                </div>

                <table class="col-md-12 mx-0 text-center text-sm" style={{background:"#f9f9f9",border:"1px solid #23fe45"}}> 
                 {orders.map((order, index) => (
                  
                   <tr key={index} >

                        <td width="70%">{order.item}</td>
                        <td width="8%">{order.qty} </td>   
                        <td width="12%">{order.qty * order.price} </td>  
                        <td width="10%">
                        <button class="btn btn-danger btn-m ms-0 my-2 me-2"  onClick={() => handleDelete(order.id)}><i class="ni ni-basket"></i></button>
                        </td>
                   </tr>
                  ))}
                </table>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">ITEM</label>
                    <input type="text" list="products" class="form-control" name="item" placeholder="Enter Item.." value={item} onChange={itemChange} required />
                    <datalist id="products">
                    {product?.data?.map(product => (
                        <option key={product.product_id} value={product.cat}>
                              {generateShortcuts(product.product_cat)} - {product.cat}
                        </option>
                    ))}
                    </datalist>           
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Quantity</label>
                    <input type="number" class="form-control" name="qty" min="1" placeholder="Enter Quantity..." value={qty}  onChange={qtyChange} required />
                                
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Price</label>
                    <input type="number" class="form-control" name="price" placeholder="Enter Price..." value={price} onChange={(e) => setPrice(e.target.value)} required />
                                
                  </div>
                </div>
                <div class="col-md-2 my-4 py-2">
                   <button class="btn btn-primary btn-sm ms-auto">ADD</button>
                </div>
                
              </div>
              </form>
              <div class="col-md-3">
                   <button class="btn btn-success btn-m ms-auto" onClick={() => handleOrder()}>Confirm Order</button>
              </div>
              <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div>
            </div>
					   </div>  
					   
					   
					   
          </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="container-fluid py-4">

      <Footer />
    </div>
  </main>

  </div>
  );
}

export default App;
