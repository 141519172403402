import React, { useEffect, useState } from 'react';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';
ReactSession.setStoreType("localStorage");

 function Category() {
    const [category, setcategory] = useState([]); 
    const [error, setError] = useState('');

   
    

    const handleChangeCategory = (maincatID, event) => {
      const newValue = event.target.value;
      setcategory((prevCategories) => {
          // if (!prevCategories || !Array.isArray(prevCategories.data)) {
          //     console.error("prevCategories is not an array:", prevCategories);
          //     return { ...prevCategories, data: [{ main_id: maincatID, maincat_name: newValue }] }; // Fallback
          // }
  
          const updatedCategories = { ...prevCategories };
          const index = updatedCategories.data.findIndex(cat => cat.main_id === maincatID);
          console.log(index);
  
          if (index !== -1) {
              updatedCategories.data[index].maincat_name = newValue;
          } else {
              console.error(`Category with main_id ${maincatID} not found.`);
          }
  
          return updatedCategories;
       
        
      });
  };

  const handleChangesubCategory = (subcatID, event) => {
    const newValue = event.target.value;
    setcategory((prevCategories) => {
        // if (!prevCategories || !Array.isArray(prevCategories.data)) {
        //     console.error("prevCategories is not an array:", prevCategories);
        //     return { ...prevCategories, data: [{ main_id: maincatID, maincat_name: newValue }] }; // Fallback
        // }

        const updatedCategories = { ...prevCategories };
        const index = updatedCategories.subcategory.findIndex(cat => cat.cat_id === subcatID);
        console.log(index);

        if (index !== -1) {
            updatedCategories.subcategory[index].cat_name = newValue;
        } else {
            console.error(`Category with main_id ${subcatID} not found.`);
        }

        return updatedCategories;
     
      
    });
};
  


    
  
    const fetchData = async () => {
    try {
        const response = await axios.get('https://projects.sninfoserv.com/fbh-api/category/');
        if(response.data.status === "success"){
            setcategory(response.data);
            console.log(response.data);
        }
       
       //navigate(`/product`);
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
    }
};

useEffect(()=>{
fetchData();
}, []);


const sendUpdatedsubategories = async (SubcatID, subcat_name) => {
  console.log(SubcatID, subcat_name);
  try {
      const payload = {id: SubcatID, subcat_name:subcat_name, action:'edit'}; 
      const response = await axios.put('https://projects.sninfoserv.com/fbh-api/subcategory/', payload);
      if (response.data.status === "success") {
        fetchData();
          console.log("Category updated successfully:", response.data);
      } else {
          console.error("Error updating category:", response.data.message);
      }
  } catch (error) {
      console.error("There was an error updating the category:", error);
  }
};


const sendUpdatedCategories = async (maincatID, maincat_name) => {
  console.log(maincatID, maincat_name);
  try {
      const payload = {id: maincatID, maincat_name:maincat_name, action:'edit'}; 
      const response = await axios.put('https://projects.sninfoserv.com/fbh-api/category/', payload);
      if (response.data.status === "success") {
        fetchData();
          console.log("Category updated successfully:", response.data);
      } else {
          console.error("Error updating category:", response.data.message);
      }
  } catch (error) {
      console.error("There was an error updating the category:", error);
  }
};


const handleChange = async(mainId, visibility) => {
  let visible = null;
  if(visibility === '1'){
   visible = '0';
  }else{
   visible = '1';
  }
  console.log(visible, mainId, visibility);
 const response = await axios.put('https://projects.sninfoserv.com/fbh-api/category/', { action:'visible', id: mainId, value:visible });
 if(response.data.status === 'success'){
   fetchData();
}
};



const handlesubcategoryChange = async(catId, visibility) => {
  let visible = null;
  if(visibility === '1'){
   visible = '0';
  }else{
   visible = '1';
  }
  console.log(visible, catId, visibility);
 const response = await axios.put('https://projects.sninfoserv.com/fbh-api/subcategory/', { action:'visible', id: catId, value:visible });
 if(response.data.status === 'success'){
   fetchData();
}
};



  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <SideBar />

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-6">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Category
                <a href="\add_category" class="btn btn-info btn-sm ms-auto" style={{float:'right',}} > <i class="fa fa-plus"></i>Add Category</a>
              </h6>
                   </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                 
                    <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">S.No</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder  opacity-7">Main Category</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Action</th>
                     
                    </tr>
                  
                  </thead>
                  <tbody>
                  {category?.data?.map((product, index)=> (
                    <tr  key={product.main_id}>
                        <td>
                        <span class="text-xs font-weight-bold"> {index+1}</span>
                      </td>

                       <td>
                       <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Product Name..."
                                    value={product.maincat_name}
                                    onChange={(event) => handleChangeCategory(product.main_id, event)}
                                />
                            </div>
                     
                        {/* <span class="text-xs font-weight-bold"> {product.maincat_name}</span> */}
                      </td>

                      <td>
                      <a  class="btn btn-info btn-sm ms-auto" onClick={() => handleChange(product.main_id, product.visibility)} style={{float:'left'}} > <i class={ product.visibility === '1' ?"fa fa-eye-slash" :"fa fa-eye"}></i></a>
                      <a onClick={()=>sendUpdatedCategories(product.main_id, product.maincat_name)} class="btn btn-primary btn-sm ms-auto" style={{float:'left',}}><i class="fa fa-edit"></i></a>
                    
                      </td>
                     
                      
                    </tr>
                    ))}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Subcategory
                <a href="\add_subcategory" class="btn btn-info btn-sm ms-auto" style={{float:'right',}} > <i class="fa fa-plus"></i>Add Subcategory</a>
              </h6>
                   </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                 
                    <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">S.No</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder  opacity-7">Main Category</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Action</th>
                     
                    </tr>
                  
                  </thead>
                  <tbody>
                  {category?.subcategory?.map((product, index)=> (
                    <tr  key={product.cat_id}>
                        <td>
                        <span class="text-xs font-weight-bold"> {index+1}</span>
                      </td>

                       <td>
                    
                       <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Product Name..."
                                    value={product.cat_name}
                                    onChange={(event) => handleChangesubCategory(product.cat_id, event)}
                                />
                            </div>
                     
                       
                      
                        {/* <span class="text-xs font-weight-bold">{product.cat_name}</span> */}
                      </td>

                      <td>
                      <a  class="btn btn-info btn-sm ms-auto" onClick={() => handlesubcategoryChange(product.cat_id, product.visibility)} style={{float:'left'}} > <i class={ product.visibility === '1' ?"fa fa-eye-slash" :"fa fa-eye"}></i></a>
                       <a onClick={()=>sendUpdatedsubategories(product.cat_id, product.cat_name)}  class="btn btn-primary btn-sm ms-auto" style={{float:'left',}}><i class="fa fa-edit"></i></a>
                    
                      </td>
                     
                      
                    </tr>
                    ))}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
   
      <Footer />
    </div>
  </main>

  </div>
  )
}
export default Category;
