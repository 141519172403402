import React, { useEffect, useState } from 'react';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';
ReactSession.setStoreType("localStorage");



 function Add_subcategory() {
    const userId = ReactSession.get("userId");
    const userType = ReactSession.get("userType");
    const userName = ReactSession.get("userName");
    const navigate = useNavigate();
    const indexPath = '/';
 
    const [category, setcategory] = useState(null); 
    const [maincat, setMaincat] = useState("");
    const [cat, setcat] = useState("");
    const [error, setError] = useState('');


    useEffect(()=>{
        const fetchData = async () => {
        try {
            const response = await axios.get('http://localhost/projects/Fbh api/api/category/');
            if(response.data.status === "success"){
                setcategory(response.data);
            }
           
           //navigate(`/product`);
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
            setError('Invalid email or password');
        }
    };
    fetchData();
}, []);




const handleMainncat = (event) => {
  setMaincat(event.target.value); // Changed setInput to setLink
};

    const handlecat = (event) => {
      setcat(event.target.value); // Changed setInput to setLink
  };

	

   



    const handleSubmit = async (event) => {
        event.preventDefault();

       
     console.log(maincat, cat);
     
      
        try {
            const response = await axios.post('http://localhost/projects/Fbh api/api/subcategory/', {maincatID: maincat, cat:cat});
            console.log(response.data);
           if(response.data.status === "success"){
            console.log(response.data);
            navigate(`/category`);
        }
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
            setError('Invalid email or password');
        }
       
    };

    
  return (
   
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
    <div class="card shadow-lg mx-2 mt-5 card-profile-bottom">
      <div class="card-body p-2">
        <div class="row gx-4">
          <div class="col-12">
               <div class="nav-wrapper position-relative end-0">
             
			  <div class="tab-content">
					
					 
        <div class="tab-pane fade show active" id="takeAway" role="tabpanel">
					  <div class="card-body">
              <p class="text-uppercase text-sm">Add Subcategory</p>
             <form onSubmit={handleSubmit}>
              <div class="row">
              <div class="col-md-3">
              <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Main Category</label>
                    <select type="text"  class="form-control" placeholder="Enter Main Category..."  value={maincat}
                                                            onChange={handleMainncat} required>
                      <option value="" disabled>Select the Main Category</option>                                     
                    {category?.data?.map(product => (
                        <option key={product.main_id} value={product.main_id}>
                             {product.maincat_name}
                        </option>
                    ))}

                    </select>
                  </div>
                  </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Subcategory Name</label>
                    <input type="text"  class="form-control"    placeholder="Enter Product Name..."  value={cat}
                                                            onChange={handlecat} />
                  </div>
                </div>
               
                <div class="col-md-2 my-4 py-2">
                   <button class="btn btn-primary btn-sm ms-auto">ADD Subcategory</button>
                </div>
               
              </div>
              </form>
             
             
              {/* <div  class="col-md-9">
                   <p style={{ color: 'red' }}>{formError.name}</p>
              </div> */}
              

                
            </div>
					   </div>

         
					   
					   
          </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="container-fluid py-4">

      <Footer />
    </div>
  </main>

  </div>
  )
}
export default Add_subcategory;