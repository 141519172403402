import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [data, setData] = useState('');

  const handlePrint = async () => {
    try {
      await axios.post('http://localhost/print/index.php', data);
      console.log("Print request sent successfully");
    } catch (error) {
      console.error('Error sending print request:', error);
    }
  };

  return (
    <div>
      <textarea value={data} onChange={(e) => setData(e.target.value)} />
      <button onClick={handlePrint}>Print</button>
    </div>
  );
}

export default App;
